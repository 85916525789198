@import "../../styles/colors.scss";

input.custom-attendee-input {
  color: $color-numeric-input !important;
  background-color: white !important;
}

input:focus.input-field-border  {
  border-color: $color-border !important;
}

.field.error input.custom-attendee-input {
  color: $color-error-input !important;
}

.field.error input:focus.input-field-border  {
  border-color: $color-error-input !important;
}

.custom-attendee-input label {
  color: $color-label !important;
}

.segment-padding {
  padding: 20px !important;
}

.selected-room {
  cursor: pointer;
  border-radius: 0px !important;

  &.isSelected {
    background-color: #e8e8e8 !important;
    box-shadow: 0 0 0 1px $color-shadow !important;
  }

  &.isNotSelected {
    background-color: white !important;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5 !important;
  }

  &.disabled {
    opacity: 0.5 !important;
  }
}

.room-name-width {
  width: 100%;
}
