@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.ui.grid> .row> .column.modal-action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  > .modal-action-button {
    width: 50%;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.header{
  color: $color-standard-subheading !important;
}

.sub-header {
  font-family: $font-primary;
  color: $color-standard-subheading !important;
  font-size: 20px !important;
}

.modal {
  padding: 40px;
  .blue-label {
    color: $color-light-blue0;
  }
  .btn-cancel {
    background-color: $color-btn-secondary !important;
    width: 96px;
    height: 36px;
    border: solid 1px !important;
    border-color: $color-btn-secondary-text !important;
    color: $color-btn-secondary-text !important;

    &:hover {
      background-color: $color-btn-secondary-hover !important;
      border: $color-btn-secondary-hover 1px solid !important;
      color: $color-btn-secondary-text !important;
    }
  }

  .btn-submit {
    background-color: $color-btn-primary !important;
    width: 96px;
    height: 36px;
    border: solid 1px $color-btn-primary !important;
    color: $color-btn-primary-text !important;

    &:hover {
      background-color: $color-btn-primary-hover !important;
      border: $color-btn-primary-hover 1px solid !important;
    }
  }
}
