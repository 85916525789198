@import "../../styles/colors.scss";

textarea#specialRequests {
  min-height: 0px !important;
  resize: none;
}

.special-requests-text-area {
  margin-bottom: 1em !important;
}

.attendee-text-area {
  margin-bottom: 0.5em !important;
}

.attendee-icon-button {
  border: 1px solid $color-border !important;
  background-color: $color-white !important;
  color: $color-icon !important;
}

.attendee-icon-button i.icon {
  color: $color-icon !important;
}
