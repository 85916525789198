$color-dark-blue0: #13233a;
$color-dark-blue1: #182d46;
$color-dark-blue2: #2c3a53;
$color-dark-blue3: #424a61;
$color-dark-blue4: #545b70;
$color-dark-blue6: #808394;
$color-dark-blue8: #b5b6c1;
$color-light-blue0: #0077bf;
$color-light-blue3: #55a4dc;
$color-light-blue8: #cedff4;
$color-light-blue9: #e5eef9;
$color-light-blue10: #f7f9fc;

$color-light-blue-alt2: #c2d6f2;

$color-text-alternative0: #ffffff;
$color-text-alternative1: #1d3451;
$color-text-alternative3: #244165;

// $color-background0: #efefef;
$color-background0: white;
$color-background1: white;
// $color-background2: #fafafb;
$color-background2: white;
// $color-background3: #fcfdff;
$color-background3: white;

$color-shadow0: #00000029;


$color-warning-text: #973937;
//$color-error-input: #ed6456;
$color-error-input: #C62931;
$color-error-input1: #fbe4dc;
$color-border-confirmation: #d1d1d1;
$color-error-text: #973937;
$color-important-button: #ea594c;
$color-box-shadow: #d4d4d5;
$color-success-input: #f2fcf6;
$color-success-input1: #01b944;

// Common header background colour
$color-grey-background: #f8f8f8;

// Black (core colour)
$color-pms-black-6: #101820;

// White (core colour)
$color-white: white;

// Warning/Validation colour
$color-asterisks: #C62931;

//Primary colour
$color-natural-tone-brown: #977035;
$color-highlight-tone-brown: #C49757;

//Secondary colour
$color-natural-tone-blue: #0C3C60;
$color-highlight-tone-blue: #1567A4;

$color-natural-red: #9B2027;

// --------- Buttons ---------

$color-btn-primary: $color-natural-tone-brown;
$color-btn-primary-hover: $color-highlight-tone-brown;
$color-btn-primary-text: $color-white;

$color-btn-secondary: $color-natural-tone-blue;
$color-btn-secondary-hover: $color-highlight-tone-blue;
$color-btn-secondary-text: $color-white;

// --------- Headings ---------

$color-standard-subheading: $color-pms-black-6;
$color-booking-h1: $color-pms-black-6;

// --------- Footer ---------

$color-footer-background: $color-pms-black-6;
$color-footer-text: $color-white;
$color-footer-header: $color-pms-black-6;
$color-footer-link-text: $color-pms-black-6;

// --------- Icon ---------

$color-icon: $color-pms-black-6;

// --------- Booking items ---------

$color-booking-item-date: $color-pms-black-6;
$color-standard-booking-item-details: $color-pms-black-6;
$color-booking-item-full-date: $color-pms-black-6;
$color-booking-item-details: $color-pms-black-6;
$color-attendee-asterisks: $color-asterisks;

// --------- General ---------

$color-p-text: $color-pms-black-6;
$color-customer-line: $color-pms-black-6;
$color-label: $color-pms-black-6;
$color-divider: $color-pms-black-6;
$color-border: $color-pms-black-6;
$color-shadow: $color-pms-black-6;
$color-back-button-text: $color-pms-black-6;
$color-profile-text: $color-pms-black-6;
$color-numeric-input: $color-pms-black-6;

$color-nav-bar-background: $color-pms-black-6;
$color-customer-group-name: $color-pms-black-6;