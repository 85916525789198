.p-sight-week-date {
    padding-top: 10px;
  }
  
  .p-booking-item-week-day {
    margin: 0;
  }
  
  // .h2-booking-item-day {
  //   font-size: 20px;
  //   color: #dd2b58;
  //   font-weight: bolder;
  //   padding: 0;
  //   margin: 0;
  //   line-height: 30px;
  // }
  
  .div-booking-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .p-booking-item-week-day {
    margin: 0;
    text-align: center;
  }
  
  // .h2-booking-item-day {
  //   font-size: 20px;
  //   color: #244165;
  //   font-weight: bolder;
  //   padding: 0;
  //   margin: 0;
  //   line-height: 30px;
  //   text-align: center;
  // }

  // .icon-booking-completed {
  //   color: #0077bf;
  // }

  .center-text {
    text-align: center;
  }

  .center-items{
    align-items: center;
  }