@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

a.item.menu-item.active-left-nav {
  font-family: $font-primary-heavy;
  font-size: 16px;
  border-bottom: 1px solid $color-text-alternative0;
  padding-bottom: 0px;
  margin-bottom: 12px;
}

.ui.label.new-label {
  margin-left: 10px !important;
  border-radius: 16px;
}

.more-span {
  display: "flex";
  align-items: "center";
}
