@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

html {
  body {
    div {
      .ui.form.customer-group-selector {
        .field {
          > label {
            font-family: $font-primary-heavy;
            font-size: 16px;
            color: $color-customer-group-name;
          }
        }
      }
    }
  }
}
