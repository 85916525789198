@font-face {
  font-family: "JostLight";
  font-style: normal;
  font-weight: 200;
  src: url("./font/Jost-200-Thin.otf");
}
 
@font-face {
  font-family: "JostNormal";
  font-style: normal;
  font-weight: 400;
  src: url("./font//Jost-400-Book.otf");
}
 
@font-face {
  font-family: 'JostHeavy';
  font-weight: 600;
  src: url("./font/Jost-600-Semi.otf");
}
 
* {
  font-family: "JostNormal";
}
 
$font-primary-light: "JostLight";
$font-primary: "JostNormal";
$font-primary-heavy: "JostHeavy";