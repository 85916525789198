@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.ui.card {
  &.booking-card {
    padding: 14px !important;
  }
}

.p-booking-item-week-day {
  margin: 0;
  text-align: center;
}

.h2-booking-item-day {
  font-size: 20px;
  color: #244165;
  font-weight: bolder;
  padding: 0;
  margin: 0;
  line-height: 30px;
}

.selected-day {
  cursor: pointer;
  border-radius: 0px !important;
  margin-bottom: 20px !important;

  &.isSelected {
    background-color: #e8e8e8 !important;
    box-shadow: 0 0 0 1px $color-shadow !important;
    z-index: 1 !important;
  }

  &.isNotSelected {
    background-color: white !important;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5 !important;
  }

  &.disabled {
    opacity: 0.5 !important;
  }
}
