@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.p-sight-week-date {
  padding-top: 10px;
}

.button-sight-week-card {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

div.ui.card.notification-card {
  width: 100%;
  box-shadow: 0 1px 3px 0 $color-box-shadow, 0 0 0 0 $color-box-shadow;
  padding: 1em;
  border-left: #fff solid 8px;
  border-radius: 0;
}

.divider-color{
  border-color: red;
}