@import "fonts.scss";
@import "variables.scss";
@import "colors.scss";

html {
  body {
    font-family: $font-primary;
    font-weight: normal;
    font-style: normal;
    background-color: $color-background0;
    background-color: #f8f8f8;

    h2 {
      font-family: $font-primary;
      line-height: 48px;
    }

    h3 {
      font-family: $font-primary-heavy;
      font-size: 18px;
    }

    .whitebackground {
      background-color: $color-text-alternative0 !important;
      box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15) !important;
    }

    div {
      & > .ui.menu:not(.top-nav-bar-container):not(.tabular):not(.pagination) {
        .item,
        .item:hover {
          font-family: $font-primary-heavy;
          color: $color-dark-blue0;
          border-radius: 0;
          padding: 16px;

          &:first-child {
            border-radius: 5px 0 0 0;
          }

          &:last-child {
            border-radius: 0 5px 0 0;
          }

          &:before {
            background: none;
          }
        }

        .item.active {
          font-family: $font-primary-heavy;
          font-weight: normal;
          border-radius: 5px 5px 0 0;
          border: 0;
          background-color: $color-text-alternative0;
          z-index: 2;
          box-shadow: 0px 3px 6px #00000029;

          &:after {
            height: 12px;
            width: 109%;
            background: white;
            content: "";
            position: absolute;
            bottom: -12px;
            left: 0px;
          }

          &:last-of-type {
            &:after {
              width: 103%;
            }
          }

          &:not(:first-child):after {
            left: -5px;
          }
        }
        .item:hover {
          color: $color-light-blue0;
        }
      }
      .ui.menu.secondary.pointing {
        .item,
        .item:hover {
          font-family: $font-primary-heavy;
          color: $color-dark-blue0;
        }
        .item.active {
          font-family: $font-primary-heavy;
          font-weight: normal;
          border-color: $color-light-blue0;
          color: $color-light-blue0;
        }
        .active.item:hover {
          border-color: $color-dark-blue0;
          color: $color-dark-blue0;
        }
      }
      .ui.attached.menu:not(.tabular) {
        background-color: transparent;
        border: none;
        .item:not(.active),
        .item.disabled {
          background: $color-light-blue9 !important;
          font-family: $font-primary;
        }

        .item.disabled:hover {
          font-family: $font-primary;
        }
      }
      .ui.attached.segment,
      .ui.segment {
        box-shadow: 0px 3px 6px $color-shadow0;
        border: none;
        border-radius: 3px;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      tr,
      td,
      .ui {
        .button:not(.icon) {
          border-radius: unset;
        }
        .text.container:not(.icon),
        .header:not(.icon),
        .input:not(.icon),
        .list:not(.icon),
        .steps:not(.icon),
        .title:not(.icon),
        .form:not(.icon),
        .menu:not(.icon),
        .message:not(.icon),
        .cards:not(.icon),
        .card:not(.icon),
        .content:not(.icon),
        .items:not(.icon),
        .statistics:not(.icon),
        .statistic:not(.icon),
        .accordion:not(.icon),
        .modal:not(.icon),
        .popup:not(.icon),
        .search:not(.icon),
        .results:not(.icon),
        .result:not(.icon),
        .category:not(.icon),
        .label:not(.icon) {
          font-family: $font-primary;
          font-weight: normal;
          font-style: normal;
        }
        .form:not(.icon) {
          .field > label {
            font-family: $font-primary-heavy;
            font-weight: 400;
          }
        }
        .sortable.table thead th {
          font-family: $font-primary-heavy;
          font-weight: normal;
          font-style: normal;
          color: $color-dark-blue1;
          background-color: $color-light-blue10;
        }
        .sortable.table thead {
          th:hover,
          th.sorted:hover,
          th.sorted {
            background-color: darken($color-light-blue10, 2%);
          }
        }

        .selectable.table tbody tr:hover {
          background-color: $color-grey-background !important; //semantic has it set as important so this is very important
        }
        .ui.styled.accordion {
          width: unset;
        }

        .table tfoot {
          background-color: $color-light-blue10;
        }

        .pagination.menu .active.item {
          background-color: $color-light-blue10;
        }
        .pagination.menu .active.item:hover {
          background-color: $color-light-blue10;
        }

        .tabular.menu .item {
          border-width: 1px;
          border-color: #e5e6e6;
        }
        .tabular.menu .item,
        .tabular.menu .item.disabled {
          border-radius: 0rem !important;
          background-color: $color-text-alternative0;
          min-width: 200px;
          justify-content: center;
        }
        .tabular.menu .active.item {
          border-radius: 0rem !important;
          background-color: $color-light-blue8;
        }
        .bottom.attached.segment.active.tab {
          border-radius: 0px 3px 3px 3px;
        }
        .ui.styled.accordion,
        .ui.styled.accordion .accordion {
          border-radius: unset;
        }
        .ui.accordion .accordion .title .dropdown.icon,
        .ui.accordion .title .dropdown.icon {
          float: right;
        }
        .ui.table thead th:not(:first-child) {
          border-left: 1px solid rgba(34, 36, 38, 0.15) !important;
        }
        .ui.table thead th {
          background-color: #f7f9fc;
        }
      }

      .ui.divider:not(.vertical):not(.horizontal) {
        border-top: none;
        border-bottom: 1px solid #c2d6f2;
      }

      @media only screen and (min-width: 1200px) {
        .ui.container {
          width: $app-layout-max-width;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .ui.link.menu .item:hover,
      .ui.menu .dropdown.item:hover,
      .ui.menu .link.item:hover,
      .ui.menu a.item:hover {
        color: #fff;
      }
    }

    .ui.menu .ui.dropdown .menu > .item:hover {
      font-weight: bold !important;
      color: $color-text-alternative3 !important;
    }
    .ui.menu .ui.dropdown .menu > .item {
      color: $color-text-alternative3 !important;
    }

    .ui.menu.top-nav-bar-container:not(.vertical) .right.item,
    .ui.menu:not(.vertical) .right.menu {
      min-width: 235px;
    }

    .ui.menu.top-nav-bar-container:not(.vertical) .left.item,
    .ui.menu:not(.vertical) :not(.dropdown) > .left.menu {
      width: 850px;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1299px) {
      .ui.container {
        min-width: 1285px;
      }
    }

    .ui.selection.dropdown {
      min-width: 13em;
    }

    .ui.error.message {
      box-shadow: 0 0 0 1px $color-error-input inset, 0 0 0 0 transparent;
      background-color: white;
      color: $color-error-input;
    }

    .ui.popup {
      max-width: none;
    }

    @media only screen and (max-width: 688px) {
      h2 {
        font-family: $font-primary;
        line-height: 26px;
        font-size: 18px;
        font-weight: normal;
      }

      div.ui.fluid.dropdown.navigation-dropdown {
        padding-bottom: 2px;
        & > div.visible.menu.transition {
          width: 100%;

          & > div.item > span.text {
            font-family: $font-primary-light;
            font-size: 18px;
            color: $color-light-blue0;
          }
        }
      }

      .ui.card,
      .ui.cards > .card {
        box-shadow: 0 3px 6px 0 $color-dark-blue8;
      }
    }

    @media only screen and (min-width: 689px) and (max-width: 1023px) {
      h2 {
        font-family: $font-primary;
        line-height: 26px;
        font-size: 18px;
        font-weight: normal;
      }

      div.ui.fluid.dropdown.navigation-dropdown {
        padding-bottom: 4px;
        & > div.visible.menu.transition {
          width: 100%;

          & > div.item > span.text {
            font-family: $font-primary-light;
            font-size: 18px;
            color: $color-light-blue0;
          }
        }
      }
    }
  }
}
