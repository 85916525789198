@import "../../styles/colors.scss";

#okta-sign-in.auth-container .button-primary {
  background: $color-natural-red !important;
  border-color: $color-natural-red !important;
  box-shadow: none !important;
}

#okta-sign-in.auth-container input[type="button"]:focus,
#okta-sign-in.auth-container input[type="submit"]:hover {
  background: $color-natural-red !important;
  border-color: $color-natural-red !important;
  box-shadow: none !important;
  opacity: 0.9;
}

#okta-sign-in.auth-container .okta-form-input-field.focused-input {
  border-color: $color-pms-black-6 !important;
}

#okta-sign-in .focused-input,
#okta-sign-in .link.help:focus,
#okta-sign-in input[type=radio]:focus+label,
#okta-sign-in input[type=text]:focus {
  box-shadow: none !important;
}

#okta-sign-in .custom-checkbox {
  display: inline-block;
}

@media (max-width: 768px) {
  #okta-sign-in.auth-container .button {
    width: 100% !important;
  }

  #okta-sign-in.auth-container .okta-form-input-field input {
    width: 100% !important;
  }

  #okta-sign-in .mfa-verify-passcode .link-button {
    margin-top: 0px !important;
  }

  #okta-sign-in .mfa-verify-passcode .auth-passcode {
    width: 100% !important;
  }
}