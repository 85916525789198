@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.p-sight-week-date {
  padding-top: 10px;
}

.p-booking-item-week-day {
  margin: 0;
}

.h2-booking-item-day {
  font-size: 20px;
  color: $color-booking-item-date;
  font-weight: bolder;
  padding: 0;
  margin: 0;
  line-height: 30px;
  text-align: center;
}

.div-booking-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon-booking-item {
  color: $color-icon;
}

.standard-booking-item-details {
  color: $color-standard-booking-item-details;
  font-weight: bolder;
}

.booking-h1-heading {
  font-size: 16px;
  color: $color-booking-h1;
  font-weight: bolder;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: $font-primary;
  margin: 0px;
}

.manage-booking-h1-heading {
  font-size: 22px;
  color: $color-booking-h1;
  font-weight: bolder;
  padding-top: 2px;
  padding-bottom: 10px;
  font-family: $font-primary;
  margin: 0px;
}

.manage-booking-h1-heading-summary {
  font-size: 16px;
  color: $color-booking-h1;
  font-weight: bolder;
  padding-top: 0px;
  font-family: $font-primary;
  margin: 0px;
}

.manage-booking-h1-heading-attendee {
  font-size: 16px;
  color: $color-booking-h1;
  font-weight: bolder;
  padding-top: 10px;
  font-family: $font-primary;
  margin: 0px;
}

.notification-card {
  background-color: white;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  border-radius: 0px;
  margin: 0px;
  margin-bottom: 14px;
  border-left: 0px;

  &.selected {
    background-color: #e8e8e8;
    box-shadow: 0 0 0 1px $color-shadow;
  }
}

.right-text {
  text-align: right;
}

.customer-group-heading {
  color: $color-customer-group-name;
  font-family: $font-primary-heavy;
  font-size: 20px;
  //padding-top: 32px;
  padding-bottom: 20px;
  float: left;
}

.div-col {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 10px;
  padding-left: 9px;
}

.location-field {
  padding-top: 10px;
  margin-bottom: 10px;
}

.segment-padding-left {
  padding-left: 20px !important;
}

.p-text-colour {
  color: $color-p-text !important;
}

.view-map {
  cursor: pointer;
  text-decoration: underline;
  border: none !important;
  background: none;
  font-size: 1rem !important;
  padding: 0 !important;
}

.room-map-label {
  color: $color-p-text !important;
  border: none !important;
  background: none;
  font-size: 1rem !important;
  padding: 0 !important;
}

.info-icon {
  color: $color-highlight-tone-blue;
  cursor: pointer;
  position: relative;
  top: 5px;
  margin-left: 2px;
  transform: scale(0.8);
}

.row-back-room-bookings {
  padding: 0px !important;
  padding-top: 20px !important;
  padding-left: 10px !important;
}

.row-padding-top {
  padding-top: 0px !important;
}

.row-padding-bottom {
  padding-bottom: 0px !important;
}

.segment-booking-card-details {
  margin-bottom: 10px !important;
  padding: 20px !important;
}

.manage-booking-asterisk {
  color: $color-attendee-asterisks;
  margin-left: 5px;
}

.column-room-padding {
  padding-bottom: 20px;
}

.error-message-attendee-total {
  display: block !important;
}

.label-color {
  color: $color-label !important;
}

.divider-colour {
  border-color: $color-divider !important;
}

.btn-primary {
  background-color: $color-btn-primary !important;
  color: $color-btn-primary-text !important;
  border-color: $color-btn-primary !important;
  font-weight: normal !important;

  &:hover {
    background-color: $color-btn-primary-hover !important;
    border: $color-btn-primary-hover 1px solid !important;
  }
}

.btn-secondary {
  background-color: $color-btn-secondary !important;
  border: solid 1px !important;
  border-color: $color-btn-secondary !important;
  color: $color-btn-secondary-text !important;

  &:hover {
    background-color: $color-btn-secondary-hover !important;
    border: $color-btn-secondary-hover 1px solid !important;
    color: $color-btn-secondary-text !important;
  }
}

.input-field-border input:focus {
  border-color: $color-border !important;
}

.input-textarea-border textarea:focus {
  border-color: $color-border !important;
}

.black-outline-input:focus {
  border: 1px solid $color-border !important;
}

.balck-outline-dropdown .ui.selection.dropdown:focus {
  border: 1px solid red !important;
}

.link {
  color: $color-back-button-text;
}

/**************** Override Autofill styles ****************/
/* For Chrome, Edge, and Safari */
.ui.form .field.field input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #fff inset !important;
  -webkit-text-fill-color: $color-pms-black-6 !important;
  border: 1px solid rgba(34, 36, 38, .15) !important;
}
.ui.form .field.field input:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #fff inset !important;
  -webkit-text-fill-color: $color-pms-black-6 !important;
  border-color: $color-border !important;
}
/* For Firefox */
.ui.form .field.field input:-moz-autofill {
  box-shadow: 0 0 0 1000px #fff inset !important;
  -webkit-text-fill-color: $color-pms-black-6 !important;
  border: 1px solid rgba(34, 36, 38, .15) !important;
}
.ui.form .field.field input:-moz-autofill:focus {
  box-shadow: 0 0 0px 1000px #fff inset !important;
  -webkit-text-fill-color: $color-pms-black-6 !important;
  border-color: $color-border !important;
}
/**************** Override Autofill styles ****************/