@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

@media only screen and (max-width: 1023px) {
  .full-width-background {
    margin-top: 63px !important;
  }
}

html body {
  .full-width-background {
    @media only screen and (min-width: 1024px) {
      min-width: 1285px;
    }

    &.login {
      width: 100%;
      background: url("../../media/background5.jpg") no-repeat center;
      background-attachment: fixed;
      background-size: cover;
    }

    &.reset-password {
      width: 100%;
      background: url("../../media/background2.jpg") no-repeat center;
      background-attachment: fixed;
      background-size: cover;
    }

    &.unlock-account {
      width: 100%;
      background: url("../../media/background3.jpg") no-repeat center;
      background-attachment: fixed;
      background-size: cover;
    }

    &.termsconditions,
    &.privacypolicy,
    &.cookies {
      width: 100%;
      background: url("../../media/background4.jpg") no-repeat center;
      background-attachment: fixed;
      background-size: cover;
    }

    &.help {
      width: 100%;
      background: url("../../media/background4.jpg") no-repeat center;
      background-attachment: fixed;
      background-size: cover;
    }
  }

  .mobile-view {
    display: none !important;
  }

  .ui.menu.top-nav-bar-container {
    min-width: 1285px;
    border-radius: 0;
    margin-bottom: 0px;
    font-size: 14px;
    height: 63px;
    position: sticky;
    top: 0px;
    z-index: 6;
    background-color: $color-nav-bar-background;

    .item,
    .item:hover {
      color: $color-text-alternative0;
      font-family: $font-primary;
    }

    .item.active-left-nav,
    .item.active-left-nav:hover {
      font-family: $font-primary-heavy;
    }

    .item:before {
      content: none;
    }

    .notification-pop-up-menu-item {
      padding: 2px;
    }
  }

  .sidebar-menu {
    min-height: 100vh;
    margin-top: -15px !important;
  }

  .header-centered-text {
    margin-top: 20px !important;
  }

  .sidebar-menu-content {
    background-color: #13233a !important;
    width: 100% !important;
  }

  .content-grid-container {
    display: grid;
    grid-template-areas: "menu main";
    grid-template-columns: min-content auto;
  }

  .leftnav-main {
    grid-area: menu;
    background-color: #13233a !important;
    width: 113px;
    text-align: center;
  }

  .leftnav-sticky {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .content-main {
    grid-area: main;
  }

  .company-logo {
    display: block;
    width: 75%;
    margin-top: 0px;
  }

  .side-visibility-toggle-container {
    padding-left: 0% !important;
    padding-right: 0% !important;
    padding-bottom: 0% !important;
    padding-top: 1% !important;
  }

  .side-visibility-toggle {
    position: relative;
    left: 0%;
  }

  .open-sidebar-button {
    padding: 11px !important;
  }

  .open-sidebar-button-icon {
    margin: 0px !important;
  }

  .page-content-container {
    min-height: 75vh;
  }

  .ui.center.aligned.header {
    padding-left: 15px;
  }

  .side-menu-display {
    display: grid !important;
  }

  .mobile-side-menu-display {
    display: none;
  }

  .menu-button {
    display: none;
  }

  .log-out-nav {
    display: flex;
    align-items: center;
    a,
    a:hover {
      color: $color-text-alternative0;
    }
  }

  .username-nav {
    display: flex;
    align-items: center;
  }

  /* Breakpoint for super low resolution devices like the iPhone 5/iPhone SE so app header won't be as squished */
  @media only screen and (max-width: 350px) {
    .mobile-view {
      display: contents !important;
    }

    .desktop {
      display: none !important;
    }

    div.ui.login-form {
      height: auto;

      div.ui.segment.login-container {
        padding: 40px 16px;
        height: auto;

        .login-header {
          color: #1d3451;
          font-family: $font-primary-light;
          font-size: 26px;
          line-height: 26px;
        }

        .sign-in-label {
          padding-top: 20px;
          font-size: 18px;
        }

        .back-navigation-icon {
          padding-top: 80px;
        }
      }
    }

    .menu-button {
      font-size: 18pt;
      display: inline;
      color: $color-text-alternative0;
      padding-right: 8px;
    }

    .mobile-side-menu-display {
      display: unset;
    }

    div.menu.transition.visible.profile-menu {
      z-index: 6000;
    }

    .company-logo {
      max-width: 38%;
    }

    .header-centered-text {
      margin-top: 15px !important;
    }

    .ui.menu {
      &.top-nav-bar-container {
        position: fixed;
        max-width: 100vw !important;
        .item.menu-item {
          display: none;

          &:not(.vertical) .right.item {
            min-width: auto;
          }
        }
        &:not(.vertical) .right.menu {
          min-width: auto;

          .ui.dropdown > .text {
            border-radius: 50%;
            border: 2px solid $color-text-alternative0;
            padding: 10px;
          }
        }
      }
    }

    .ui.menu .log-out-nav {
      display: none;
    }
  }

  @media only screen and (min-width: 351px) and (max-width: 688px) {
    .mobile-view {
      display: contents !important;
    }

    .desktop {
      display: none !important;
    }

    div.ui.login-form {
      height: auto;

      div.ui.segment.login-container {
        padding: 40px 16px;
        height: auto;

        .login-header {
          color: #1d3451;
          font-family: $font-primary-light;
          font-size: 26px;
          line-height: 26px;
        }

        .sign-in-label {
          padding-top: 20px;
          font-size: 18px;
        }

        .back-navigation-icon {
          padding-top: 80px;
        }
      }
    }

    .menu-button {
      font-size: 18pt;
      display: inline;
      color: $color-text-alternative0;
      padding-right: 8px;
    }

    .mobile-side-menu-display {
      display: unset;
    }

    div.menu.transition.visible.profile-menu {
      z-index: 6000;
    }

    .company-logo {
      max-width: 50%;
    }

    .header-centered-text {
      margin-top: 15px !important;
    }

    .ui.menu {
      &.top-nav-bar-container {
        position: fixed;
        max-width: 100vw !important;
        .item.menu-item {
          display: none;

          &:not(.vertical) .right.item {
            min-width: auto;
          }
        }
        &:not(.vertical) .right.menu {
          min-width: auto;

          .ui.dropdown > .text {
            border-radius: 50%;
            border: 2px solid $color-text-alternative0;
            padding: 10px;
          }
        }
      }
    }

    .ui.menu .log-out-nav {
      display: none;
    }
  }

  @media only screen and (min-width: 689px) and (max-width: 1023px) {
    .mobile-view {
      display: contents !important;
    }

    .desktop {
      display: none !important;
    }

    .page-content-container {
      min-height: 50vh;
    }

    div.ui.login-form {
      height: auto;

      div.ui.segment.login-container {
        padding: 40px 16px;
        height: auto;

        .login-header {
          color: #1d3451;
          font-family: $font-primary-light;
          font-size: 26px;
          line-height: 26px;
        }

        .sign-in-label {
          padding-top: 20px;
          font-size: 18px;
        }

        .back-navigation-icon {
          padding-top: 80px;
        }
      }
    }

    .menu-button {
      font-size: 18pt;
      display: inline;
      color: $color-text-alternative0;
      padding-right: 8px;
    }

    .mobile-side-menu-display {
      display: unset;

      div.menu.transition.visible.profile-menu {
        z-index: 6000;
      }
    }

    .company-logo {
      max-width: 50%;
    }

    .header-centered-text {
      margin-top: 15px !important;
    }

    .ui.menu {
      &.top-nav-bar-container {
        position: fixed;
        min-width: 100vw !important;
        max-width: 100vw !important;
        .item.menu-item {
          display: none;

          &:not(.vertical) .right.item {
            min-width: auto;
          }
        }
        &:not(.vertical) .right.menu {
          min-width: auto;

          .ui.dropdown > .text {
            border-radius: 50%;
            border: 2px solid $color-text-alternative0;
            padding: 10px;
          }
        }
      }
    }

    .ui.menu .log-out-nav {
      display: none;
    }
  }

  @media only screen and (max-width: 1250px) {
    .ui.menu .username-nav {
      display: none;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1400px) {
    .smaller-view {
      display: contents !important;
    }

    .hide-smaller-view {
      display: none !important;
    }

    .ui.menu:not(.vertical) .right.item, .ui.menu:not(.vertical) .right.menu {
      justify-content: flex-end;
    }
  }

  @media only screen and (max-width: 1023px) {
    .ui.menu.top-nav-bar-container {
      min-width: 100px;
    }

    div.ui.container.main-content-hide-mobile {
      display: none;
    }

    .ui.compact.segment.mobile-message {
      display: auto;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 62vh;
      margin-top: 90px;

      & > div {
        line-height: 26px;
      }
    }

    .ui.compact.segment.desktop-message {
      display: none;
    }
  }

  @media only screen and (min-width: 1024px) {
    .main-content-hide-mobile {
      display: auto;
    }

    .ui.compact.segment.mobile-message {
      display: none;
    }

    .ui.compact.segment.desktop-message {
      display: auto;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 62vh;
      margin-top: 90px;
    }
  }
  .ui-divider {
    width: 80%;
    margin: 8px auto;
    border-bottom: 1px solid #ccc;
  }
}