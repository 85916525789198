.p-sight-week-date {
  padding-top: 10px;
}

.p-booking-item-week-day {
  margin: 0;
}

.h2-booking-item-day {
  font-size: 20px;
  color: #244165;
  font-weight: bolder;
  padding: 0;
  margin: 0;
  line-height: 30px;
}

.icon {
  margin-bottom: 7px;
}
