@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

html body {
  div.customer-selection-container {
    min-height: 110px;
  }

  div.ui.raised.customer-selection-slider-segment {
    background-color: $color-light-blue10;
    position: fixed;
    top: 60px;
    width: 100%;
    left: 0;
    border-radius: 0;
    padding-bottom: 30px;
    z-index: 5;

    .blue-header {
      font-family: $font-primary;
      font-size: 16px;
      color: $color-light-blue0;
      padding-top: 0;
      margin-top: -8px;
    }

    form.ui.form > div.fields > div {
      margin-bottom: 20px;

      & > label {
        font-family: $font-primary;
        font-size: 14px;
        color: $color-text-alternative1;
      }

      div.text {
        font-family: $font-primary;
        font-size: 16px;
        color: $color-dark-blue0;
        line-height: 22px;
      }
    }

    .minimized-customer-selector {
      .standard-small-heading {
        line-height: 20px;
      }
    }
  }

  button.ui.button.circular-button {
    margin-top: 10px;
    position: absolute;
    left: 43%;
    border-radius: 50% !important;
    background-color: $color-text-alternative0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 42px;
    width: 42px;

    & > svg:only-child {
      margin-left: -18px;
      margin-top: -7px;
      color: $color-light-blue0;
      width: 35px;
      height: 35px;
    }
  }
}
