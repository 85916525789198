@import "colors.scss";
@import "fonts.scss";

html {
  body {
    div {
      .ui[class*="right floated"].button {
        float: right;
        margin-right: 0;
        margin-left: 16px;
      }
      .ui[class*="right floated"].buttons {
        float: right;
        margin-right: 0;
        margin-left: 16px;
      }
      .ui[class*="left floated"].button {
        float: left;
        margin-right: 16px;
        margin-left: 0;
      }
      .ui[class*="left floated"].buttons {
        float: left;
        margin-right: 16px;
        margin-left: 0;
      }
      .ui.button {
        &.primary {
          font-family: $font-primary;
          background-color: $color-light-blue0;
          border: $color-light-blue0 1px solid;
          border-radius: 2px;

          &:hover {
            background-color: $color-light-blue3;
            border: $color-light-blue3 1px solid;
          }
        }
        &.secondary {
          font-family: $font-primary;
          background-color: $color-background1;
          border: $color-light-blue0 1px solid;
          border-radius: 2px;
          color: $color-light-blue0;
          font-weight: normal;
          &:hover {
            background-color: $color-light-blue9;
            border: $color-light-blue0 1px solid;
            color: $color-light-blue0;
          }
          &:active:not(:hover) {
            background-color: $color-background1;
            border: $color-light-blue0 1px solid;
            color: $color-light-blue0;
          }
          &:focus:not(:hover) {
            background-color: $color-background1;
            border: $color-light-blue0 1px solid;
            color: $color-light-blue0;
          }

          &.red {
            border-color: $color-important-button;
            color: $color-important-button;
            margin-right: 16px;

            &:hover {
              background-color: $color-error-input1;
            }
          }
        }

        & svg {
          margin-left: 16px;

          &:only-child {
            margin-left: 0;
          }
        }
      }
      .ui.labeled.icon.button {
        > svg.icon {
          top: 5px;
          height: 70%;
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }
  }
}
