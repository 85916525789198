@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.booking-item-date {
  display: flex;
  align-items: center;
  gap: 8px;
}

.booking-item-h1-heading {
  font-size: 16px;
  color: $color-booking-item-full-date;
  font-weight: bolder;
  padding-top: 0px;
  font-family: $font-primary;
  margin: 0px;
}

.booking-item-h2-heading {
  font-size: 14px;
  color: $color-booking-item-details;
  font-weight: bolder;
  padding-top: 0px;
  font-family: $font-primary;
  margin: 0px;
}

.booking-item-trash {
  background: #5a0e0e;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  margin-left: 0px;
}

.booking-item-spacing {
  margin: 0px;
}

.duplicate-booking-item {
  border: 1px solid $color-error-input !important;
}

.booking-item-card {
  border-left: 0px !important;
  margin-bottom: 14px;
  margin: 0px;
  border-radius: 0px;

  &.isSelected {
    background-color: #e8e8e8;
    box-shadow: 0 0 0 1px $color-shadow;
  }

  &.isNotSelected {
    background-color: white;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  }

  &.duplicate-booking-item {
    border: 1px solid $color-error-input !important;

    .booking-item-h1-heading {
      color: $color-error-input;
    }

    .icon-booking-item {
      color: $color-error-input !important;
    }

    .booking-item-edit-icon {
      border-color: $color-error-input !important;
    }

    .booking-item-trash-icon {
      border-color: $color-error-input !important;
    }
  }
}

.booking-item-edit-icon {
  background-color: $color-white !important;
  margin-left: 0px;
  border: 1px solid $color-border !important;
  margin-top: 1em !important;

  &.isSelected {
    box-shadow: 0 0 0 0.5px $color-border !important;
    background-color: #e8e8e8 !important;
  }

  &.isNotSelected {
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5 !important;
  }
}

.booking-item-trash-icon {
  background-color: $color-white !important;
  margin-left: 0px;
  border: 1px solid $color-border !important;
  margin-top: 1em !important;
}
