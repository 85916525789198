@import "../../../styles/colors.scss";
@import "../../../styles/fonts.scss";

.booking-time-slot-card {
  padding: 1em !important;
  border-radius: 0 !important;
  display: flex !important;
  justify-content: space-between !important;
  margin: 0.5em !important;
}

.p-booking-item-week-day {
  margin: 0;
  text-align: center;
  color: #000000de;
}

.selected-timeSlot {
  cursor: pointer;
  border-radius: 0px !important;

  &.isSelected {
    background-color: #e8e8e8 !important;
    box-shadow: 0 0 0 1px $color-shadow !important;
  }

  &.isNotSelected {
    background-color: white !important;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5 !important;
  }

  &.disabled {
    opacity: 0.5 !important;
  }
}
