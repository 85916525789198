
.custom-3x3-grid:before {
  display: grid;
  gap: 2px;
  font-size: 1.2em;
  margin-left: 10px;
}

i.icon.home {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
