@import "semantic-css-overrides.scss";
@import "semantic-css-overrides-modal.scss";
@import "semantic-css-overrides-label.scss";
@import "semantic-css-overrides-button.scss";
@import "semantic-css-overrides-table.scss";
@import "fonts.scss";
@import "colors.scss";

.header-button-container {
  float: right;
}

.header-container {
  text-align: center;
}

.header-button {
  background-color: white;
  border: 2px solid #8c8c8c;
  color: #8c8c8c;
}

.ui.inverted.dimmer .content .loading-text {
  font-family: $font-primary-light;
  font-size: 20px;
  color: $color-dark-blue0;
  margin-top: 0px;
  padding-left: 7px;
}

.ui.table tfoot tr th.no-left-border {
  border-left: none;
}

.action-button {
  background-color: #666666 !important;
  color: white !important;
  font-size: 10pt !important;
}

.form-container {
  padding: 30px;
  border-radius: 6px;
  border: 1px solid #f2f2f2;
  box-shadow: 1px 1px 4px 4px #e6e6e6;
  height: auto;
}

.flex-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;
}

.flex-child {
  flex: 1;
}

.filter-fields {
  margin-bottom: 14px;
}

.filter-field-container {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin-bottom: 24px;
}

.filter-field-child {
  width: 60%;
}

.filter-left-fields {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  float: left;
}

.filter-field,
.ui.selection.dropdown.filter-field {
  padding-right: 10px;
}

.filter-right-fields {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.toggle-checkbox-parent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.bold {
  font-weight: bold;
}

.toggle-checkbox-heading {
  padding-right: 10px;
  font-weight: bold;
  padding-bottom: 10px;
}

.title-divider {
  opacity: 50%;
}

.customer-group {
  margin-bottom: 10pt;
}

.read-only-input > div.ui.input > input {
  cursor: default;
}

.noborders {
  border: none !important;
  box-shadow: none !important;
}

.requestComponentHeight {
  display: flex !important;
  flex-flow: column !important;
  height: 100% !important;
}
.currency:before {
  content: "$";
}
.currency:after {
  content: ".00";
}

.currency {
  align-content: right !important;
}

.buttonPadding {
  padding-bottom: 42px !important;
}

.selection-box {
  background-color: #ebebed;
  padding: 30px !important;
  display: flex;
  justify-content: flex-start;
}

.sub-selection-box {
  background-color: #e5eef9;
  padding: 30px !important;
  display: flex;
  justify-content: flex-start !important;
  margin-top: 20px;
}

.divider-column {
  border-left: 1px #cbcbcd solid;
  width: unset !important;
}

.standard-checkbox {
  font-size: 15px/24px;
  font-weight: bolder;
}

.standard-selection-checkbox {
  padding-left: 20px;
  padding-right: 20px;
}

.standard-selection-first-checkbox {
  padding-right: 20px;
}

.standard-radio {
  font-size: 15px/24px;
  font-weight: bolder;
}

.standard-selection-first-radio {
  padding-right: 20px;
}

.standard-selection-radio {
  padding-left: 20px;
  padding-right: 20px;
}

.standard-heading {
  font-size: 24px;
  color: $color-standard-subheading;
  font-weight: bolder;
  padding-top: 20px;
  padding-bottom: 20px;
}

.standard-subheading {
  font-size: 22px;
  color: $color-standard-subheading;
  font-family: $font-primary-heavy;
  padding-top: 20px;
}

.standard-small-heading {
  font-size: 16px;
  color: $color-standard-subheading;
  font-weight: bolder;
  padding-top: 20px;
}

.standard-text {
  font-size: 0.92857143em !important;
  color: #244165 !important;
  font-weight: 700;
  padding-bottom: 10px;
}

.standard-accordion-title {
  color: #545b70;
}

.accordion-icon {
  float: right;
}

.reset-width {
  width: unset !important;
}

.reset-padding {
  padding: unset !important;
}

.full-width {
  width: 100% !important;
}

.segment-radio-box {
  width: 60%;
}

.custom-radio {
  margin-left: 10px;
  margin-right: 5px;
}

.segment-content {
  padding-top: 10px !important;
}

.space-bottom {
  margin-bottom: 20px;
}

.space-top {
  margin-top: 20px !important;
}

.move-up {
  margin-top: -20px !important;
}

.header-button {
  margin-top: 0;
}

/* TABLE TITLE HEADERS STYLING */
.table-title-header-general {
  font-family: $font-primary-heavy;
}

/* ACTION BUTTON GENERAL STYLING */
.action-button-general {
  font-family: $font-primary-heavy;
}

// Need to override default styles in order to make button look as needed
.custom-filter-button {
  border: 1px solid #244165 !important;
}

.accordion-header {
  width: 100%;
  background: #f7f9fc;
  border: 1px solid #e2e2e3;
  border-radius: 4px 4px 0px 0px;
  opacity: 1;
  padding-left: 5px;
  height: 47px;
  line-height: 47px;
  padding: 15px;
}
.accordion-body {
  width: 100%;
  padding-left: 1px;
  padding-right: 1px;
}
.button-text {
  font-size: 10pt;
  float: left;
  margin-top: 1px;
  margin-bottom: -2px;
  color: #244165;
}

.button-icon {
  height: 16px;
  width: 16px;
  margin-left: 14px;
  margin-bottom: -6px;
  float: right;
}

.button-icon-clicked {
  height: 16px;
  width: 16px;
  margin-left: -4px;
  margin-right: -4px;
  margin-bottom: -4px;

  @media only screen and (max-width: 700px) {
    .header-button {
      margin-top: -32px !important;
    }

    .filter-field-container {
      display: flex;
      flex-direction: column;
      width: 80%;
      justify-content: space-between;
      margin-bottom: 24px;
    }

    .filter-field-child {
      width: 80%;
      padding-bottom: 10px;
    }
  }
}

.component-loader-container .row.mt-5.mb-5 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

a:focus {
  outline: none;
}
.back-navigation-button-container {
  padding: 0px !important;
  background-color: transparent !important;
}

.back-navigation-button-text {
  font-family: $font-primary-heavy;
}

.back-navigation-button-text-color {
  color: $color-back-button-text !important;
}

html {
  body {
    div {
      .ui.sortable.table {
        .high-light {
          background-color: $color-light-blue8;
        }
        .high-light:hover {
          background-color: lighten($color-light-blue8, 2%);
        }
      }
    }
  }
}

.loader-component {
  height: 150px;
}

svg.information-popup {
  color: $color-dark-blue0;
}

.error-info-icon {
  color: $color-error-input;
}

.error-text{
  color: $color-error-text;
}

.numeric-input {
  text-align: right;
}

div.right.aligned.floated.three.wide.column.current-sight-heading {
  color: $color-text-alternative1;
  font-family: $font-primary-heavy;
  font-size: 20px;
  margin-top: 32px;
  margin-bottom: 20px;
  float: right;
}

/*notifications*/
.Toastify__toast-container--top-right {
  top: 5em;
  right: 1em;
}

.Toastify__toast {
  font-family: $font-primary;
}

.Toastify__toast-body {
  padding: 16px;
}

.Toastify__toast--info {
  background: $color-light-blue9;
  border: 1px solid $color-light-blue0;
  color: $color-light-blue0;

  .Toastify__close-button {
    color: $color-light-blue0;
  }

  .Toastify__progress-bar {
    background-color: $color-light-blue0;
  }
}

.Toastify__toast--error,
.Toastify__toast--warn {
  background: $color-error-input1;
  border: 1px solid $color-error-input;
  color: $color-error-input;

  .Toastify__close-button {
    color: $color-error-input;
  }

  .Toastify__progress-bar {
    background-color: $color-error-input;
  }
}

.Toastify__toast--success {
  background: $color-success-input;
  border: 1px solid $color-success-input1;
  color: $color-success-input1;

  .Toastify__close-button {
    color: $color-success-input1;
  }

  .Toastify__progress-bar {
    background-color: $color-success-input1;
  }
}

.toggle-field {
  display: flex;
  align-items: flex-end;

  label {
    padding-left: 1em;
  }
}

.flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
  p.margin-bottom-1 {
    margin-bottom: 1em;
  }
}

span.sub-heading-text {
  padding-top: 0.5em;
}

.ui.button.bank-list-edit-button {
  margin-bottom: 1em;
  margin-left: 1em;
}

.ui.margin-bottom-1.button {
  margin-bottom: 1em;
}

.ui.form {
  .width-100 {
    width: 100%;
  }
}

.ui.form .field {
  input[readonly] {
    color: $color-dark-blue6;
  }
}

.ui.button.margin-left-1 {
  margin-left: 1em;
}

.ui.form .fields .field label {
  color: $color-p-text;
}

p {
  color: $color-p-text;
}

.extra-vertical-padding {
  padding-top: 20px;
  padding-bottom: 20px;
}

.header-dropdown-option-icon {
  margin-right: 0.5em;
  color: $color-icon;

  + span {
    color: $color-dark-blue0;
  }
}

div.row.divider-container {
  margin-top: -50px;
}

div.row.divider-container-less {
  margin-top: -30px;
}

.ui.grid > .row.customer-selection-header {
  position: sticky;
  top: 63px;
  z-index: 5;
  padding-top: 2rem;
  background-color: $color-grey-background;
  padding-bottom: 0px;
  .column .border {
    //border-bottom: 1px solid $color-light-blue-alt2;
    border-bottom: 1px solid $color-customer-line;
  }
}

.ui.grid > .row.first-row-after-customer-selection-header {
  padding-top: none;
}

//Explan styles moved here due to weird css ordering error that prevents successful build
html body .explan-content {
  .explan-table {
    &.ui.table thead tr:nth-child(1) > th {
      position: sticky;
      top: 0px;
      z-index: 1;
    }
    &.ui.table thead tr th:first-child,
    tbody tr td:first-child {
      width: 300px;
    }
  }

  div .ui.order-type-selector-container {
    background-color: $color-grey-background;
    box-shadow: none;
    padding: 0.5rem;

    .order-button {
      border-radius: 3px;
      background: $color-grey-background;
      border: none;
      margin-right: 16px;

      &.active {
        background-color: $color-background1;
        border-radius: 3px;
        -moz-box-shadow: 3px 3px 5px 6px #ccc;
        -webkit-box-shadow: 3px 3px 5px 6px #ccc;
        box-shadow: 3px 3px 5px 1px #ccc;
      }
    }
  }

  .ui.grid > .row > div.column.standard-subheading-explan {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 16px;

    .standard-small-heading {
      font-size: 18px;
      font-family: $font-primary;
      font-weight: normal;
    }
  }

  .ui.grid > .row > div.column.save-warning-text {
    color: $color-warning-text;
    display: flex;
    align-items: center;

    & > svg {
      margin-right: 16px;
    }
  }

  .error {
    color: $color-warning-text;
  }

  .ui.raised.segment.fixed-add-total-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    background: $color-light-blue9;
    border: solid 1px $color-border-confirmation;
    font-family: $font-primary-heavy;
    font-size: 18px;
    color: $color-dark-blue3;
    box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.75);
    margin-bottom: 0;

    .centered-text {
      line-height: 36px;
    }

    .ui.error.message {
      font-size: 14px;
      text-align: center;
    }
  }

  .ui.button.secondary.red-button {
    border-color: $color-important-button;
    color: $color-important-button;

    &:hover {
      border-color: $color-important-button;
      color: $color-important-button;
      background-color: $color-error-input1;
    }
  }
}

div.explan-mobile-content {
  input {
    border: 1pt solid $color-border-confirmation;
    width: 100%;
    height: 3rem;
    padding: 16px;
    margin-bottom: 16px;
  }
}

.ui.button.margin-top-bottom {
  margin-top: 16px;
  margin-bottom: 16px;
}
//End of explan styles

.flipped-icon {
  transform: scaleX(-1);
}

@media only screen and (max-width: 688px) {
  .standard-heading {
    font-size: 22px;
    padding-top: 16px;
    margin-bottom: 16px;
    padding-bottom: 10px;

    &.blue-underline {  
      border-bottom: 2px solid $color-standard-subheading;
      padding-bottom: 5px;
    }
  }

  .standard-subheading {
    font-size: 16px;
    padding-top: 0px;
    margin-bottom: 8px;
    padding-bottom: 0;
  }

  .standard-small-heading {
    font-size: 12px;
    padding-top: 0;
    font-family: $font-primary;
    font-weight: normal;
    line-height: 18px;
  }

  .ui.grid > .row.first-row-after-customer-selection-header:not(.dashboard):not(.current-sight) {
    padding: 0;
    height: 0;
  }
}

@media only screen and (min-width: 689px) and (max-width: 1023px) {
  .standard-heading {
    font-size: 22px;
    padding-top: 16px;
    margin-bottom: 16px;
    padding-bottom: 10px;

    &.blue-underline {  
      border-bottom: 2px solid $color-standard-subheading;
      padding-bottom: 5px;
    }
  }

  .standard-subheading {
    font-size: 16px;
    padding-top: 0px;
    margin-bottom: 8px;
    padding-bottom: 0;
  }

  .standard-small-heading {
    font-size: 16px;
    padding-top: 0;
    font-family: $font-primary;
    font-weight: normal;
    line-height: 18px;
  }

  .ui.grid > .row.first-row-after-customer-selection-header:not(.dashboard):not(.current-sight) {
    padding: 0;
    height: 0;
  }
}

input, textarea {
  font-family: $font-primary !important;
}