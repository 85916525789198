@import "variables.scss";
@import "colors.scss";

html {
  body {
    table.ui.table {
      thead {
        tr {
          th {
            color: $color-dark-blue2 !important;
            padding: 16px;
            height: 76px;

            &.sticky {
              position: sticky;
              bottom: 0;
            }
          }
        }
      }
      tbody, tfoot {
        tr {
          td {
            color: $color-dark-blue6 !important;
            height: 76px;
            padding: 16px;
          }
        }
      }
    }
  }
}
